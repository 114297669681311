import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout/page/page"

const CancellationPage = () => (
  <Layout>
    <SEO title="Cancellation Policy" keywords="terms, policy" />
    <PageLayout name="Cancellation Policy" />
    <section style={{ marginBottom: "70px" }}>
      <div className="container">
        <div className="row" style={{ padding: "0 15px" }}>
          <div className="privacy-content">
            <div className="">
              <h1 className="sans">Hungry Hippo Solutions</h1>
              <div className="d-flex">
                <p className="sans p-update">
                  URL: <Link to="/">https://www.hungryhipposolutions.com/</Link>
                </p>
              </div>
            </div>
            <p className="sans p-details">
              The website listed above, hereinafter referred to as "Website" is
              operated by the following party:
              <br />
              <br />
              Magi Media Group, a corporation, incorporated under the laws of
              the state of California (hereinafter referred to as "Operator")
              <br />
              <br />
              For any questions or comments regarding this Cancellation Policy,
              customers may visit the following link:
              <Link to="/"> https://www.hungryhipposolutions.com/</Link>
              <br />
              <br />
              Cancellation of the monthly service provided by Hungry Hippo
              Solutions must be submitted in writing to
              office@hungryhipposolutions.com and confirmation of the written
              request must be received 25 days prior to the next calendar
              billing cycle.
              <br />
              <br />
              If you are billed on the 28th of each month as an example. Your
              cancellation request must be received by the 3rd of that month in
              order to avoid another monthly charge for the service.
              <br />
              <br />
              This 25 calendar days allow for the needed items to be
              discontinued and service to be stopped and any files, access or
              possible assets in possession to be returned.
              <br />
              <br />
              Cancellations can not be accepted verbally.
              <br />
              <br />
              Given the nature and scope of the work done, Magi Media Group and
              Hungry Hippo Solutions take a considerable amount of time to
              setup, develop, implement a new website for each client and can
              not recover that investment of time that goes into making each
              website setup a success.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CancellationPage
